import {useContext, useState} from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import './Login.scss'
import API, {getMe, HandleError} from "../API";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import {Link, Navigate} from "react-router-dom";
import LoginHeader from "../components/LoginHeader";
import LoginFooter from "../components/LoginFooter";

export default function Login() {
    const { enqueueSnackbar } = useSnackbar()
    const app = useContext(AppContext)
    const [form, setForm] = useState({
        values: {
            username: '',
            password: ''
        },
        errorFields: {},
        error: false,
        loading: false,
    })

    const [redirect, setRedirect] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!form.values.username || !form.values.password)
            return;

        setForm({...form, loading: true })
        try {
            const resToken = await API({
                method: 'POST',
                url: `/login`,
                data: {...form.values}
            })

            if (resToken.status < 200 || resToken.status > 299)
                throw resToken.data;

            const resMe = await getMe(resToken.data.token)
            if (resMe.status < 200 || resMe.status > 299)
                throw resMe

            setForm({...form, loading: false})
            app.login(resToken.data.token, resMe.user, {...app.ui, ...resMe.ui})
            setRedirect('/');
        }
        catch(e) {
            setForm({...form, loading: false})
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    const onChange = (e) => {
        let values = {...form.values}
        values[e.target.name] = e.target.value
        setForm({...form, values: values})
    }

    return (redirect ? <Navigate to={redirect} /> : <div id="page-login">
        <LoginHeader />
        <Container>
            <div className="login-form-box">
                <div className="form-inner">
                    <div className="form-login">
                        <h2 className="mb-4 fw-bold">Admin Login</h2>
                        <p>
                            Login to admin dashboard using your admin credentials.
                        </p>
                        <form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3 form-floating" controlId="username">
                                <Form.Control type="text" placeholder="Username" name="username" value={form.values.username} onChange={onChange} />
                                <Form.Label>Username</Form.Label>
                                {'username' in form.errorFields ? <Form.Text className="text-muted">
                                    {form.errorFields['username']}
                                </Form.Text> : ''}
                            </Form.Group>
                            <Form.Group className="mb-3 form-floating" controlId="password">
                                <Form.Control type="password" placeholder="Password" name="password" value={form.values.password} onChange={onChange} />
                                <Form.Label>Password</Form.Label>
                                {'password' in form.errorFields ? <Form.Text className="text-muted">
                                    {form.errorFields['password']}
                                </Form.Text> : ''}
                            </Form.Group>
                            <Button variant="primary" size="lg" type="submit">Login</Button>
                        </form>
                    </div>
                </div>
                <LoginFooter />
            </div>
        </Container>
    </div>)
}