import './Dashboard.scss'
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {Col, Container, Row} from "react-bootstrap";
import {useContext} from "react";
import AppContext from "../AppContext";
import Wallet from "../components/Wallet";

export default function Dashboard() {
    const app = useContext(AppContext)

    return <div id="page-dashboard" className={"page " + (app.ui.isDrawerOpen ? 'menu-shown' : '')}>
        <Header />
        <Container className="page-container">
            <Sidebar />
            <div className="page-inner">
                <Container>
                    <Row>
                        <Col sm={8}>
                            <h5><strong>Dashboard</strong></h5>
                            <p>
                               Show some charts & numbers...
                            </p>
                        </Col>
                        <Col sm={4}>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Container>
    </div>
}