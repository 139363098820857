import {Col, Container, Nav, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import logo from "../assets/img/logo.webp";
import "./LoginHeader.scss";

export default function LoginHeader() {
    return <header id="login-header">
        <Container>
            <Row>
                <Col className="main-logo">
                    <Link to="/">
                        <img src={logo} alt="" />
                    </Link>
                </Col>
                <Col className="nav-menu">
                    <Nav className="me-auto">
                        <Nav.Link href="/login">Inloggen</Nav.Link>
                        <Nav.Link href="/signup" className="btn bg-secondary">Aanmelden</Nav.Link>
                    </Nav>
                </Col>
            </Row>
        </Container>
    </header>
}