import {Link, useLocation} from "react-router-dom";
import logo from '../assets/img/logo.webp'
import {FiLogOut, FiSettings} from "react-icons/fi";
import {Alert, Badge, Button} from "react-bootstrap";
import './Sidebar.scss'
import {GoHomeFill} from "react-icons/go";
import {FaCoins, FaMoneyBillTrendUp, FaPlay, FaSliders, FaUsers} from "react-icons/fa6";
import {IoMdHelpCircleOutline, IoMdSchool} from "react-icons/io";
import {FaChartLine, FaExchangeAlt, FaHome, FaRegQuestionCircle, FaSchool} from "react-icons/fa";
import {useContext} from "react";
import AppContext from "../AppContext";
import {HiBars3} from "react-icons/hi2";
import {PiChartLineUp} from "react-icons/pi";
import {AiOutlineHome} from "react-icons/ai";

export default function Sidebar() {
    const app = useContext(AppContext)
    const location = useLocation()
    const activeClass = (path) => {
        if(location.pathname === path)
            return 'active'

        return ''
    }

    const reload = () => {
        window.location.reload();
    }

    return <>
        <div id="sidebar-backdrop" onClick={app.toggleDrawer}></div>
        <aside id="sidebar">
            <div className="sidebar-scrollable">
                {app.ui.new_version !== '' &&
                <Alert variant="info" style={{margin: '-15px 15px 10px 15px', fontSize: 14}}>
                    <Alert.Heading style={{fontSize: 16}} className="d-flex justify-content-start gap-2 align-items-center pb-2">Nieuwe versie beschikbaar!</Alert.Heading>
                    <p>
                        Je kijkt nu naar een verouderde versie van de site. Je kan nu gebruik maken van de nieuwe versie door de pagina te refreshen of je kan klikken op de onderstaande link.
                    </p>
                    <Alert.Link onClick={reload}>Klik hier <span className="fw-normal">voor de nieuwste versie!</span></Alert.Link>
                </Alert>}
                <nav className="main-nav">
                    <ul>
                        <li>
                            <Link to="/" className={activeClass("/")} onClick={app.closeDrawer}>
                                <FaHome /><span>Dashboard</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/users" className={activeClass("/users")} onClick={app.closeDrawer}>
                                <FaUsers /><span>Users</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/leads" className={activeClass("/leads")} onClick={app.closeDrawer}>
                                <FaChartLine /><span>Leads</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/transactions" className={activeClass("/transactions")} onClick={app.closeDrawer}>
                                <FaExchangeAlt /><span>Transacties</span>
                            </Link>
                        </li>
                        <li className="pt-5">
                            <Link to="/logout">
                                <FiLogOut /><span>Uitloggen</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </aside>
    </>
}