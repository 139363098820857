import {Model} from "./Model";

export class User extends Model {
    first_name;
    last_name;
    email;
    iban;
    swift;
    version;
    username;
    balance;
    lead_counts;
    price;
    min_payout;
    suspended;
}