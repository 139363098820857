import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import {useContext, useState} from "react";
import AppContext from "../AppContext";
import "./Settings.scss";
import {useSnackbar} from "notistack";
import API, {HandleError} from "../API";
import {Lead} from "../models/Lead";
import {FaLandmark, FaLock, FaUser} from "react-icons/fa6";

export default function Settings() {
    const app = useContext(AppContext)
    const { enqueueSnackbar } = useSnackbar()
    const [form, setForm] = useState({
        values: {
            first_name: app.user?.first_name || '',
            last_name: app.user?.last_name || '',
            email: app.user?.email || '',
            old_password: '',
            new_password: '',
            confirm_password: '',
            iban: app.user?.iban || '',
            swift: app.user?.swift || ''
        },
        errorFields: {},
        error: false,
        loading: false,
    })

    const onChange = (e) => {
        let values = {...form.values}
        values[e.target.name] = e.target.value
        setForm({...form, values: values})
    }

    const handleSubmit = async (e, form_id) => {
        e.preventDefault();
        setForm({...form, loading: false, errorFields: {}})
        try {
            let formData = {};
            switch(form_id) {
                case 'personal':
                    formData = {
                        'form_id': form_id,
                        'first_name': form.values.first_name,
                        'last_name': form.values.last_name,
                        'email': form.values.email,
                    }
                    break;
                case 'bank':
                    formData = {
                        'form_id': form_id,
                        'iban': form.values.iban,
                        'swift': form.values.swift
                    }
                    break;
                case 'password':
                    if(form.values.new_password == '')
                        return;

                    if(form.values.new_password != '' && form.values.new_password !== form.values.confirm_password) {
                        setForm({...form, loading: false, errorFields: {'confirm_password': 'Passwords don\'t match.'}})
                        return;
                    }
                    formData = {
                        'form_id': form_id,
                        'old_password': form.values.old_password,
                        'new_password': form.values.new_password
                    }
                    break;
                default:
                    return;
            }

            const res = await API({
                method: 'POST',
                url: `/account/${app.user?.ID}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: formData
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            app.login(app.token, res.data.user, {...app.ui})
            enqueueSnackbar(`Account updated successfully.`, {variant: 'success', style: {whiteSpace: 'pre-line'}});
            setForm({...form, errorFields: {}, error: false, values: {...form.values, new_password: '', confirm_password: '', old_password: '' }})
        }
        catch(e) {
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
            if(e?.response?.data?.error?.errors) {
                const errorFields = {}
                for (let k in e.response.data.error.errors)
                    errorFields[k] = e.response.data.error.errors[k];

                setForm({...form, loading: false, errorFields: errorFields, values: {...form.values, new_password: '', confirm_password: '', old_password: '' }})
            }
            else {
                setForm({...form, loading: false, errorFields: {}, error: false, values: {...form.values, new_password: '', confirm_password: '', old_password: '' }})
            }
        }
    }

    console.log(form.errorFields);

    return <div id="page-settings" className={"page " + (app.ui.isDrawerOpen ? 'menu-shown' : '')}>
        <Header />
        <Container className="page-container">
            <Sidebar />
            <div className="page-inner">
                <h4 className="fw-bolder mb-5">Accountinstellingen</h4>
                <Form onSubmit={(e) => handleSubmit(e, 'personal')}>
                    <h4 className="mb-4"><FaUser /> &nbsp; Persoonlijke Informatie</h4>
                    <Form.Group as={Row} className="mb-3" controlId="f_first_name">
                        <Form.Label column sm={4}>
                            Voornaam
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text" name="first_name" value={form.values.first_name} onChange={onChange} />
                            {'first_name' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['first_name']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="f_last_name">
                        <Form.Label column sm={4}>
                            Achternaam
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text" name="last_name" value={form.values.last_name} onChange={onChange} />
                            {'last_name' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['last_name']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="f_email">
                        <Form.Label column sm={4}>
                            E-mail
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="email" name="email" value={form.values.email} onChange={onChange} />
                            {'email' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['email']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-5">
                        <Button type="submit" size="lg">Opslaan</Button>
                    </Form.Group>
                </Form>
                <Form onSubmit={(e) => handleSubmit(e, 'bank')}>
                    <h4 className="mb-4 mt-5"><FaLandmark /> &nbsp; Bankinformatie</h4>
                    <Form.Group as={Row} className="mb-3" controlId="f_iban">
                        <Form.Label column sm={4}>
                            IBAN
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text" name="iban" value={form.values.iban} onChange={onChange} />
                            {'iban' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['iban']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="f_swift">
                        <Form.Label column sm={4}>
                            SWIFT
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text" name="swift" value={form.values.swift} onChange={onChange} />
                            {'swift' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['swift']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-5">
                        <Button type="submit" size="lg">Opslaan</Button>
                    </Form.Group>
                </Form>
                <Form onSubmit={(e) => handleSubmit(e, 'password')}>
                    <h4 className="mb-4 mt-5"><FaLock /> &nbsp; Wachtwoord Bijwerken</h4>
                    <p className="text-muted small">Om veiligheidsredenen moet je het huidige wachtwoord invoeren bij het bijwerken van je wachtwoord.</p>
                    <Form.Group as={Row} className="mb-5" controlId="f_old_password">
                        <Form.Label column sm={4}>
                            Huidig Wachtwoord
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="password" name="old_password" value={form.values.old_password} onChange={onChange} />
                            {'old_password' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['old_password']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="f_new_password">
                        <Form.Label column sm={4}>
                            Nieuw Wachtwoord
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="password" name="new_password" value={form.values.new_password} onChange={onChange} />
                            {'new_password' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['new_password']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="f_confirm_password">
                        <Form.Label column sm={4}>
                            ... nog een keer
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="password" name="confirm_password" value={form.values.confirm_password} onChange={onChange} />
                            {'confirm_password' in form.errorFields ? <Form.Text className="text-muted">
                                {form.errorFields['confirm_password']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    <Form.Group className="mb-3 mt-5">
                        <Button type="submit" size="lg">Wachtwoord Bijwerken</Button>
                    </Form.Group>
                </Form>
                <Form>
                    <h4 className="mb-4 mt-5">Account Verwijderen</h4>
                    <p className="text-muted">
                        Als je je account wilt verwijderen, klik dan op de onderstaande knop. Deze actie kan niet ongedaan worden gemaakt en al je informatie wordt permanent verwijderd.
                    </p>
                    <Form.Group className="mb-3 mt-5">
                        <Button type="submit" variant="danger">Account Verwijderen</Button>
                    </Form.Group>
                </Form>
            </div>
        </Container>
    </div>
}