import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {Badge, Button, Col, Container, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AppContext from "../AppContext";
import {FaExternalLinkAlt, FaInfoCircle, FaUserCog} from "react-icons/fa";
import "./Leads.scss";
import {Lead} from "../models/Lead";
import {useSnackbar} from "notistack";
import API, {HandleError} from "../API";
import {
    fdate,
    fdatetime,
    fill_table_from_url,
    lead_status_badge,
    lead_status_text,
    make_url,
    strip_url_params
} from "../Common";
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem'
import {User} from "../models/User";
import {Link} from "react-router-dom";

export default function Leads() {

    const app = useContext(AppContext)
    const initTable = {
        items: [],
        page: 1,
        pages: 1,
        itemCount: 0,
        perPage: 0,
        search: '',
        status: '',
        claimed: ''
    }
    const [table, setTable] = useState(initTable)
    const [modal, setModal] = useState({
        loading: true,
        open: false,
        item: null,
        errorFields: {},
        error: false,
        submitting: false
    })

    const { enqueueSnackbar } = useSnackbar()

    const leadStatuses = {
        'granted': 'Granted',
        'pending': 'On hold',
        'cancelled': 'Cancelled'
    }

    useEffect(() => {
        getItems()
    }, [])

    useEffect(() => {
        getItems()
    }, [table.status, table.claimed])

    useEffect(() => {

        let params = {
            page: table.page,
            search: table.search,
            status: table.status,
            claimed: table.claimed
        };

        let url = make_url(strip_url_params(window.location.href), params);
        window.history.replaceState(null, null, url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.page, table.search, table.status, table.claimed])

    fill_table_from_url(initTable, ['page', 'search', 'status', 'claimed'])

    const getItems = async (page) => {
        try {
            const res = await API({
                method: 'GET',
                url: `/leads?page=${page ?? table.page}&s=${table.search ?? ''}&status=${table.status ?? ''}&claimed=${table.claimed !== '' ? table.claimed : ''}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let items = res.data.table.items.map((jsonData, idx) => {
                return new Lead(jsonData)
            })
            setTable({...res.data.table, items: items})
        }
        catch(e) {
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    let paginationItems = [];
    for (let number = 1; number <= table.pages; number++) {
        paginationItems.push(
            <PageItem key={number} active={number === table.page} onClick={() => getItems(number)}>
                {number}
            </PageItem>,
        );
    }

    const handleTableChange = async (e) => {
        let values = {...table}
        values[e.target.name] = e.target.value
        setTable({...values })
    }

    const onSearchSubmit = async (e) => {
        e.preventDefault()
        await getItems()
    }

    const closeModal = () => {
        document.getElementById(`tr-${modal.item.ID}`).classList.remove('selected')
        setModal({...modal, loading: false, open: false, item: null})
    }

    const openModal = async (item_id) => {
        document.getElementById(`tr-${item_id}`).classList.add('selected')
        setModal({...modal, loading: true, open: true, item: null })
        try {
            const res = await API({
                method: 'GET',
                url: `/lead/${item_id}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let item = new Lead(res.data.item)
            setModal({...modal, open: true, loading: false, item: item})
        }
        catch(e) {
            setModal({...modal, loading: false, open: false, item: null })
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    const onModalChange = (e) => {
        let values = {...modal.item}
        values[e.target.name] = e.target.value
        setModal({...modal, item: values})
    }
    const onModalCheckChange = (e) => {
        let values = {...modal.item}
        values[e.target.name] = e.target.checked ? 1 : 0;
        setModal({...modal, item: values})
    }
    const handleModalSubmit = async () => {
        setModal({...modal, loading: false, open: true, submitting: true })
        try {
            const res = await API({
                method: 'PUT',
                url: `/lead/${modal.item?.ID}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: modal.item
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            await getItems(table.page)

            let item = new Lead(res.data.item)
            setModal({...modal, open: true, loading: false, submitting: false, item: item})
            enqueueSnackbar('Lead updated successfully.', {variant: 'success', style: {whiteSpace: 'pre-line'}});
        }
        catch(e) {
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
            if(e?.response?.data?.error?.errors) {
                const errorFields = {}
                for (let k in e.response.data.error.errors)
                    errorFields[k] = e.response.data.error.errors[k];

                setModal({...modal, loading: false, open: true, submitting: false, errorFields: errorFields})
            }
            else {
                setModal({...modal, loading: false, open: true, submitting: false, errorFields: {}})
            }
        }
    }

    return <div id="page-leads" className={"page " + (app.ui.isDrawerOpen ? 'menu-shown' : '')}>
        <Header />
        <Container className="page-container">
            <Sidebar />
            <div className="page-inner">
                <Container>
                    <Row className="table-header">
                        <Col>
                            <h5><strong>Leads</strong></h5>
                            <p>
                                <small>Total leads: {table.itemCount}</small>
                            </p>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3 form-floating" controlId="claimed">
                                <Form.Select name="claimed" onChange={handleTableChange} value={table.claimed}>
                                    <option value="">- All -</option>
                                    <option value="0">Claimable</option>
                                    <option value="1">Claimed</option>
                                </Form.Select>
                                <Form.Label>Claimed</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3 form-floating" controlId="status">
                                <Form.Select name="status" onChange={handleTableChange} value={table.status}>
                                    <option value="">- All -</option>
                                    {Object.keys(leadStatuses).map((k, idx) => {
                                        return <option value={k}>{leadStatuses[k]}</option>
                                    })}
                                </Form.Select>
                                <Form.Label>Status</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col>
                            <form onSubmit={onSearchSubmit}>
                                <Form.Group className="mb-3 form-floating" controlId="search">
                                    <Form.Control type="search" placeholder="Search" name="search" value={table.search} onChange={handleTableChange} />
                                    <Form.Label>Search</Form.Label>
                                </Form.Group>
                            </form>
                        </Col>
                    </Row>
                    <Table borderless>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Lead</th>
                            <th>User ID</th>
                            <th>Claim Status</th>
                            <th>Datum</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {table.items.map((item, idx) => {
                            return <tr key={`lead-item-${idx}`} id={`tr-${item.ID}`} onDoubleClick={() => openModal(item.ID)}>
                                <th>{item.ID}</th>
                                <td className="lead-name">
                                    <div>
                                        {item?.first_name} {item?.last_name} #{item?.ref_id} &nbsp; <a target="_blank" href={`https://ttsys.ovh/students?s=${item?.ref_id}`}><FaExternalLinkAlt /></a>

                                    </div>
                                </td>
                                <td><Link to={`/users?search=${item.user_id}`}>#{item.user_id}</Link></td>
                                <td>
                                    {item.claim_status !== '' ? <>{item.claim_status} / <Link to={`/transactions?search=${item.claim_id}`}>{fdate(item.date_claimed)} #{item.claim_id}</Link></> : ''}
                                </td>
                                <td>{fdate(item.date_created)}</td>
                                <td>
                                    {lead_status_badge(item.status)}
                                </td>
                            </tr>
                        })}
                        {table.items.length < 1 && <tr><td colSpan={4} className="text-muted">No leads found.</td></tr>}
                        </tbody>
                    </Table>
                    {table.pages > 1 ?
                        <Pagination>
                            <Pagination.First disabled={table.page === 1} onClick={() => getItems(1)} />
                            <Pagination.Prev disabled={table.page === 1} onClick={() => getItems(table.page - 1)} />
                            {paginationItems}
                            <Pagination.Next disabled={table.page >= table.pages} onClick={() => getItems(table.page + 1)}  />
                            <Pagination.Last disabled={table.page >= table.pages} onClick={() => getItems(table.pages)}  />
                        </Pagination> : ''}
                </Container>
            </div>
        </Container>
        <Modal show={modal.open} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{modal.loading ? 'Loading lead...' : `Lead #${modal.item?.ID}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modal.loading === false ? <Form onSubmit={(e) => { e.preventDefault() }}>
                    <Row className="mb-3">
                        <Col sm={4}>
                            Affiliate User
                        </Col>
                        <Col sm={8}>
                            <a target="_blank" href={`/users?search=${modal.item?.user_id}`}>#{modal.item?.user_id}</a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={4}>
                            Student
                        </Col>
                        <Col sm={8}>
                            <a target="_blank" href={`https://ttsys.ovh/students?s=${modal.item?.ref_id}`}>{modal.item?.first_name} {modal.item?.last_name} #{modal.item?.ref_id}</a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={4}>
                            Registered
                        </Col>
                        <Col sm={8}>
                            {fdatetime(modal.item?.date_created)}
                        </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-3" controlId="f_status">
                        <Form.Label column sm={4}>
                            Status
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Select name="status" onChange={onModalChange} value={modal.item?.status}>
                            {Object.keys(leadStatuses).map((k, idx) => {
                                return <option value={k}>{leadStatuses[k]}</option>
                            })}
                            </Form.Select>
                        </Col>
                    </Form.Group>
                </Form> : <div className="text-center py-4"><Spinner animation="border" variant="primary" /></div> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleModalSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}