import {Model} from "./Model";

export class Lead extends Model {
    first_name;
    last_name;
    status;
    user_id;
    ref_id;
    date_created;
    date_claimed;
    claim_id;
    claim_status;
}