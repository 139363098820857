import './Header.scss'
import {Button, Col, Container, NavDropdown, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {GoInfo} from "react-icons/go";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";
import {useContext, useEffect, useState} from "react";
import AppContext from "../AppContext";
import {useSnackbar} from "notistack";
import {Link} from "react-router-dom";
import logo from "../assets/img/logo.webp";
import {HiBars3} from "react-icons/hi2";

export default function Header() {
    const app = useContext(AppContext)
    const { enqueueSnackbar } = useSnackbar()

    return <>
        <header id="header">
            <Container>
                <Row>
                    <Col>
                        <Button id="sidebar-toggle" onClick={app.toggleDrawer}>
                            <HiBars3 />
                        </Button>
                    </Col>
                    <Col className="main-logo">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>
                    </Col>
                    <Col className="header-user-info mt-3 mt-md-0">
                        <NavDropdown title={app.user?.email} id="header-user">
                            <NavDropdown.Item href="/settings">Instellingen</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="/logout">Uitloggen</NavDropdown.Item>
                        </NavDropdown>
                    </Col>
                </Row>
            </Container>
        </header>
        <section id="notices">
            <Container fluid={true}>

            </Container>
        </section>
    </>
}