import {Model} from "./Model";

export class Claim extends Model {
    first_name;
    last_name;
    iban;
    swift;
    status;
    amount;
    notes;
    date_completed;
    date_created;
}