import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {Col, Container, Row} from "react-bootstrap";
import {useContext} from "react";
import AppContext from "../AppContext";
import {Link} from "react-router-dom";

export default function FAQ() {
    const app = useContext(AppContext)

    return <div id="page-faq" className={"page " + (app.ui.isDrawerOpen ? 'menu-shown' : '')}>
        <Header />
        <Container className="page-container">
            <Sidebar />
            <div className="page-inner">
                <h2 className="fw-bolder mb-5">Meest gestelde vragen</h2>
                <Row>
                    <Col md={6}>
                        <h4>Statusinformatie</h4>
                        <p>
                            <strong>in behandeling</strong> - betekent dat de affiliate nog niet heeft betaald of dat er geen examen is ingepland
                            <br />
                            <strong>goedgekeurd</strong> - de affiliate heeft betaald & het examen is geboekt
                            <br />
                            <strong>geannuleerd</strong> - de affiliate heeft geen interesse
                        </p>
                    </Col>
                    <Col md={6} className="mt-5 mt-md-0">
                        <h4>Hoe verdien ik geld?</h4>
                        <p>
                            Elke succesvolle lead genereert €10 voor jou en geeft de student €10 korting bij registratie.
                        </p>
                    </Col>
                </Row>
                <Row>
                    <Col md={6} className="mt-5">
                        <h4>Hoe krijg ik uitbetaald?</h4>
                        <p>
                            Zodra je minimaal €{app.user?.min_payout * app.user?.price} ({app.user?.min_payout} goedgekeurde leads) hebt bereikt, kun je een opname van het beschikbare saldo aanvragen.
                            <br />
                            <br />
                            <Link to="/balance" className="fw-bold">Mijn Saldo</Link>
                        </p>
                    </Col>
                    <Col md={6} className="mt-5">
                        <h4>Is er een limiet?</h4>
                        <p>
                            Nee, er is absoluut geen limiet.
                            <br />
                            Meer leads, meer geld voor jou!
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
    </div>
}