import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {Badge, Button, Col, Container, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AppContext from "../AppContext";
import {FaExternalLinkAlt, FaInfoCircle, FaUserCog} from "react-icons/fa";
import "./Transactions.scss";
import {Claim} from "../models/Claim";
import {useSnackbar} from "notistack";
import API, {HandleError} from "../API";
import {
    claim_status_badge,
    fdate,
    fdatetime, fill_table_from_url,
    lead_status_badge,
    lead_status_text,
    make_url,
    strip_url_params
} from "../Common";
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem'
import {User} from "../models/User";
import {Link} from "react-router-dom";
import {FaLandmark, FaUsers} from "react-icons/fa6";

export default function Transactions() {

    const app = useContext(AppContext)
    const initTable = {
        items: [],
        page: 1,
        pages: 1,
        itemCount: 0,
        perPage: 0,
        search: '',
        status: '',
    }
    const [table, setTable] = useState(initTable)
    const [modal, setModal] = useState({
        loading: true,
        open: false,
        item: null,
        values: {
            status: '',
            notes: ''
        },
        errorFields: {},
        error: false,
        submitting: false
    })

    const { enqueueSnackbar } = useSnackbar()

    const claimStatuses = {
        'requested': 'Requested',
        'denied': 'Denied',
        'completed': 'Completed'
    }

    useEffect(() => {
        getItems()
    }, [])

    useEffect(() => {
        getItems()
    }, [table.status])

    useEffect(() => {

        let params = {
            page: table.page,
            search: table.search,
            status: table.status
        };

        let url = make_url(strip_url_params(window.location.href), params);
        window.history.replaceState(null, null, url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.page, table.search, table.status])

    fill_table_from_url(initTable, ['page', 'search', 'status'])

    const getItems = async (page) => {
        try {
            const res = await API({
                method: 'GET',
                url: `/claims?page=${page ?? table.page}&s=${table.search ?? ''}&status=${table.status ?? ''}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let items = res.data.table.items.map((jsonData, idx) => {
                return new Claim(jsonData)
            })
            setTable({...res.data.table, items: items})
        }
        catch(e) {
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    let paginationItems = [];
    for (let number = 1; number <= table.pages; number++) {
        paginationItems.push(
            <PageItem key={number} active={number === table.page} onClick={() => getItems(number)}>
                {number}
            </PageItem>,
        );
    }

    const handleTableChange = async (e) => {
        let values = {...table}
        values[e.target.name] = e.target.value
        setTable({...values })
    }

    const onSearchSubmit = async (e) => {
        e.preventDefault()
        await getItems()
    }

    const closeModal = () => {
        document.getElementById(`tr-${modal.item.ID}`).classList.remove('selected')
        setModal({...modal, loading: false, open: false, item: null, values: {
            status: '',
            notes: ''
        }})
    }

    const openModal = async (item_id) => {
        document.getElementById(`tr-${item_id}`).classList.add('selected')
        setModal({...modal, loading: true, open: true, item: null })
        try {
            const res = await API({
                method: 'GET',
                url: `/claim/${item_id}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let item = new Claim(res.data.item)
            setModal({...modal, open: true, loading: false, item: item, values: {
                status: item.status,
                notes: item.notes
            }})
        }
        catch(e) {
            setModal({...modal, loading: false, open: false, item: null })
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    const onModalChange = (e) => {
        let values = {...modal.values}
        values[e.target.name] = e.target.value
        setModal({...modal, values: values})
    }
    const handleModalSubmit = async () => {
        setModal({...modal, loading: false, open: true, submitting: true })
        try {
            const res = await API({
                method: 'PUT',
                url: `/claim/${modal.item?.ID}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: modal.values
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            await getItems(table.page)

            let item = new Claim(res.data.item)
            setModal({...modal, open: true, loading: false, submitting: false, item: item})
            enqueueSnackbar('Transaction updated successfully.', {variant: 'success', style: {whiteSpace: 'pre-line'}});
        }
        catch(e) {
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
            if(e?.response?.data?.error?.errors) {
                const errorFields = {}
                for (let k in e.response.data.error.errors)
                    errorFields[k] = e.response.data.error.errors[k];

                setModal({...modal, loading: false, open: true, submitting: false, errorFields: errorFields})
            }
            else {
                setModal({...modal, loading: false, open: true, submitting: false, errorFields: {}})
            }
        }
    }

    return <div id="page-transactions" className={"page " + (app.ui.isDrawerOpen ? 'menu-shown' : '')}>
        <Header />
        <Container className="page-container">
            <Sidebar />
            <div className="page-inner">
                <Container>
                    <Row className="table-header">
                        <Col>
                            <h5><strong>Transactions</strong></h5>
                            <p>
                                <small>Total transactions: {table.itemCount}</small>
                            </p>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3 form-floating" controlId="status">
                                <Form.Select name="status" onChange={handleTableChange} value={table.status}>
                                    <option value="">All</option>
                                    {Object.keys(claimStatuses).map((k, idx) => {
                                        return <option value={k}>{claimStatuses[k]}</option>
                                    })}
                                </Form.Select>
                                <Form.Label>Status</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col>
                            <form onSubmit={onSearchSubmit}>
                                <Form.Group className="mb-3 form-floating" controlId="search">
                                    <Form.Control type="search" placeholder="Search" name="search" value={table.search} onChange={handleTableChange} />
                                    <Form.Label>Search</Form.Label>
                                </Form.Group>
                            </form>
                        </Col>
                    </Row>
                    <Table borderless>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Transaction</th>
                            <th>Amount</th>
                            <th>Datum</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {table.items.map((item, idx) => {
                            return <tr key={`lead-item-${idx}`} id={`tr-${item.ID}`} onDoubleClick={() => openModal(item.ID)}>
                                <th>{item.ID}</th>
                                <td className="lead-name">
                                    <div>
                                        {item?.first_name} {item?.last_name} #{item?.user_id} &nbsp; <a target="_blank" href={`/users?search=${item?.user_id}`}><FaExternalLinkAlt /></a>
                                        {item?.notes != '' && item?.notes?.length > 0 && <div className="icon">
                                            <FaInfoCircle color="#bbb" />
                                            <div className="lead-popup">
                                                <h5>Notes</h5>
                                                <div dangerouslySetInnerHTML={{__html: item?.notes }} />
                                            </div>
                                        </div>}
                                    </div>
                                </td>
                                <td>&euro;{item?.amount}</td>
                                <td>{fdate(item.date_created)}</td>
                                <td>
                                    {claim_status_badge(item.status)}
                                </td>
                            </tr>
                        })}
                        {table.items.length < 1 && <tr><td colSpan={4} className="text-muted">No transactions found.</td></tr>}
                        </tbody>
                    </Table>
                    {table.pages > 1 ?
                        <Pagination>
                            <Pagination.First disabled={table.page === 1} onClick={() => getItems(1)} />
                            <Pagination.Prev disabled={table.page === 1} onClick={() => getItems(table.page - 1)} />
                            {paginationItems}
                            <Pagination.Next disabled={table.page >= table.pages} onClick={() => getItems(table.page + 1)}  />
                            <Pagination.Last disabled={table.page >= table.pages} onClick={() => getItems(table.pages)}  />
                        </Pagination> : ''}
                </Container>
            </div>
        </Container>
        <Modal show={modal.open} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{modal.loading ? 'Loading transaction...' : `Transaction #${modal.item?.ID}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modal.loading === false ? <Form onSubmit={(e) => { e.preventDefault() }}>
                    <Row className="mb-3">
                        <Col sm={4}>
                            User
                        </Col>
                        <Col sm={8}>
                            <a target="_blank" href={`/users?search=${modal.item?.user_id}`}>{modal.item?.first_name} {modal.item?.last_name} #{modal.item?.user_id}</a>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={4}>
                            Requested
                        </Col>
                        <Col sm={8}>
                            {fdatetime(modal.item?.date_created)}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col sm={4}>
                            Amount
                        </Col>
                        <Col sm={8}>
                            <h4>&euro;{modal.item?.amount}</h4>
                        </Col>
                    </Row>
                    <h5 className="mb-4 mt-5"><FaLandmark /> &nbsp; Bankinformatie</h5>
                    <Form.Group as={Row} className="mb-3" controlId="f_iban">
                        <Form.Label column sm={4}>
                            Account Holder
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text" readOnly={true} defaultValue={modal.item?.first_name + ' ' + modal.item?.last_name} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="f_iban">
                        <Form.Label column sm={4}>
                            IBAN
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text" readOnly={true} defaultValue={modal.item?.iban} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="f_swift">
                        <Form.Label column sm={4}>
                            SWIFT
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text" readOnly={true} defaultValue={modal.item?.swift} />
                        </Col>
                    </Form.Group>
                    <h5 className="mb-4 mt-5"><FaUserCog /> &nbsp; Options</h5>
                    <p className="text-muted small">
                        <strong>Please Note:</strong>
                        <br />
                        Denied requests cannot be updated.
                        <br />
                        If you deny request, funds will be returned to user's account.
                    </p>
                    <Form.Group as={Row} className="mb-3" controlId="f_status">
                        <Form.Label column sm={4}>
                            Status
                        </Form.Label>
                        <Col sm={8}>
                            {modal.item?.status !== 'denied' ?
                                <Form.Select name="status" onChange={onModalChange} value={modal.values.status}>
                                    {Object.keys(claimStatuses).map((k, idx) => {
                                        return <option value={k}>{claimStatuses[k]}</option>
                                    })}
                                </Form.Select> : <Form.Control type="text" disabled defaultValue={claimStatuses[modal.values.status]} />}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="f_notes">
                        <Form.Label column sm={4}>
                            Notes
                        </Form.Label>
                        <Col sm={8}>
                            {modal.item?.status !== 'denied' ?
                                <Form.Control as="textarea" rows={3} name="notes" onChange={onModalChange} value={modal.values.notes} /> : <Form.Control as="textarea" rows={3} disabled defaultValue={modal.values.notes} />}
                        </Col>
                    </Form.Group>
                    <h5 className="mb-4 mt-5"><FaUsers /> &nbsp; Leads ({modal.item !== null ? Object.keys(modal.item?.leads).length : 0})</h5>
                    <Row className="mb-3">
                        {modal.item !== null && Object.keys(modal.item?.leads).map((lead_id, lidx) => {
                            let lead = modal.item?.leads[lead_id]
                            return <Col sm={4}>
                                <a target="_blank" href={`https://ttsys.ovh/students?s=${lead?.ref_id}`}>{lead?.first_name} {lead?.last_name} #{lead?.ref_id}</a>
                            </Col>
                        })}
                    </Row>
                </Form> : <div className="text-center py-4"><Spinner animation="border" variant="primary" /></div> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={closeModal}>
                    Close
                </Button>
                {modal.item?.status !== 'denied' ?
                <Button variant="primary" onClick={handleModalSubmit}>
                    Save
                </Button> : ''}
            </Modal.Footer>
        </Modal>
    </div>
}