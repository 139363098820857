import {useContext, useEffect, useState} from "react";
import AppContext from "../AppContext";
import {Navigate} from "react-router-dom";

export default function Logout() {
    const app = useContext(AppContext)
    const [go, setGo] = useState(false)
    useEffect(() => {
        if(app.token === '')
            setGo(true)
        else {
            app.clearLogin()
            setGo(true)
        }
    }, [app])
    return (go ? <Navigate to="/login" /> : <div>Logging out...</div>)
}