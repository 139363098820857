import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import {Button, Col, Container, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {useContext, useEffect, useState} from "react";
import AppContext from "../AppContext";
import {FaInfoCircle, FaUserCog} from "react-icons/fa";
import "./Users.scss";
import {useSnackbar} from "notistack";
import API, {HandleError} from "../API";
import {fdate, fill_table_from_url, lead_status_badge, lead_status_text, make_url, strip_url_params} from "../Common";
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem'
import {User} from "../models/User";
import {FaLandmark, FaUser, FaWallet} from "react-icons/fa6";

export default function Users() {

    const app = useContext(AppContext)
    const initTable = {
        items: [],
        page: 1,
        pages: 1,
        itemCount: 0,
        perPage: 0,
        search: '',
        suspended: ''
    }
    const [table, setTable] = useState(initTable)
    const [modal, setModal] = useState({
        loading: true,
        open: false,
        item: null,
        errorFields: {},
        error: false,
        submitting: false
    })

    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        getItems()
    }, [])
    useEffect(() => {
        getItems()
    }, [table.suspended])

    useEffect(() => {

        let params = {
            page: table.page,
            search: table.search,
            suspended: table.suspended
        };

        let url = make_url(strip_url_params(window.location.href), params);
        window.history.replaceState(null, null, url);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.page, table.search, table.suspended])

    fill_table_from_url(initTable, ['page', 'search', 'suspended'])

    const getItems = async (page) => {
        try {
            const res = await API({
                method: 'GET',
                url: `/users?page=${page ?? table.page}&s=${table.search ?? ''}&suspended=${table.suspended !== '' ? table.suspended : ''}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let items = res.data.table.items.map((jsonData, idx) => {
                return new User(jsonData)
            })
            setTable({...res.data.table, items: items})
        }
        catch(e) {
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    let paginationItems = [];
    for (let number = 1; number <= table.pages; number++) {
        paginationItems.push(
            <PageItem key={number} active={number === table.page} onClick={() => getItems(number)}>
                {number}
            </PageItem>,
        );
    }

    const handleTableChange = async (e) => {
        let values = {...table}
        values[e.target.name] = e.target.value
        setTable({...values })
    }

    const onSearchSubmit = async (e) => {
        e.preventDefault()
        await getItems()
    }

    const closeModal = () => {
        document.getElementById(`tr-${modal.item.ID}`).classList.remove('selected')
        setModal({...modal, loading: false, open: false, item: null})
    }

    const openModal = async (item_id) => {
        document.getElementById(`tr-${item_id}`).classList.add('selected')
        setModal({...modal, loading: true, open: true, item: null })
        try {
            const res = await API({
                method: 'GET',
                url: `/user/${item_id}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: {}
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            let item = new User(res.data.item)
            setModal({...modal, open: true, loading: false, item: item})
        }
        catch(e) {
            setModal({...modal, loading: false, open: false, item: null })
            HandleError(e, 'Error', enqueueSnackbar, app.logout);
        }
    }

    const onModalChange = (e) => {
        let values = {...modal.item}
        values[e.target.name] = e.target.value
        setModal({...modal, item: values})
    }
    const onModalCheckChange = (e) => {
        let values = {...modal.item}
        values[e.target.name] = e.target.checked ? 1 : 0;
        setModal({...modal, item: values})
    }
    const handleModalSubmit = async () => {
        setModal({...modal, loading: false, open: true, submitting: true })
        try {
            const res = await API({
                method: 'PUT',
                url: `/user/${modal.item?.ID}`,
                headers: {
                    'Authorization': `Bearer ${app.token}`,
                },
                data: modal.item
            })

            if (res.status < 200 || res.status > 299)
                throw res.data;

            await getItems(table.page)

            let item = new User(res.data.item)
            setModal({...modal, open: true, loading: false, submitting: false, item: item})
            enqueueSnackbar('User updated successfully.', {variant: 'success', style: {whiteSpace: 'pre-line'}});
        }
        catch(e) {

            HandleError(e, 'Error', enqueueSnackbar, app.logout);
            if(e?.response?.data?.error?.errors) {
                const errorFields = {}
                for (let k in e.response.data.error.errors)
                    errorFields[k] = e.response.data.error.errors[k];

                setModal({...modal, loading: false, open: true, submitting: false, errorFields: errorFields})
            }
            else {
                setModal({...modal, loading: false, open: true, submitting: false, errorFields: {}})
            }
        }
    }

    return <div id="page-users" className={"page " + (app.ui.isDrawerOpen ? 'menu-shown' : '')}>
        <Header />
        <Container className="page-container">
            <Sidebar />
            <div className="page-inner">
                <Container>
                    <Row className="table-header">
                        <Col>
                            <h5><strong>Users</strong></h5>
                            <p>
                                <small>Total users: {table.itemCount}</small>
                            </p>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3 form-floating" controlId="suspended">
                                <Form.Select name="suspended" onChange={handleTableChange} value={table.suspended}>
                                    <option value="">All</option>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </Form.Select>
                                <Form.Label>Suspended</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col>
                            <form onSubmit={onSearchSubmit}>
                                <Form.Group className="mb-3 form-floating" controlId="search">
                                    <Form.Control type="search" placeholder="Search" name="search" value={table.search} onChange={handleTableChange} />
                                    <Form.Label>Search</Form.Label>
                                </Form.Group>
                            </form>
                        </Col>
                    </Row>
                    <Table borderless>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Name</th>
                            <th>E-mail</th>
                            <th>Created</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {table.items.map((item, idx) => {
                            return <tr key={`user-item-${idx}`} id={`tr-${item.ID}`} onDoubleClick={() => openModal(item.ID)}>
                                <th>{item.ID}</th>
                                <td className="user-name">
                                    {item.first_name} {item.last_name}
                                </td>
                                <td>{item.email}</td>
                                <td>{fdate(item.date_created)}</td>
                                <td>
                                    {item.suspended ? 'suspended' : 'active'}
                                </td>
                            </tr>
                        })}
                        {table.items.length < 1 && <tr><td colSpan={4} className="text-muted">No users found.</td></tr>}
                        </tbody>
                    </Table>
                    {table.pages > 1 ?
                        <Pagination>
                            <Pagination.First disabled={table.page === 1} onClick={() => getItems(1)} />
                            <Pagination.Prev disabled={table.page === 1} onClick={() => getItems(table.page - 1)} />
                            {paginationItems}
                            <Pagination.Next disabled={table.page >= table.pages} onClick={() => getItems(table.page + 1)}  />
                            <Pagination.Last disabled={table.page >= table.pages} onClick={() => getItems(table.pages)}  />
                        </Pagination> : ''}
                </Container>
            </div>
        </Container>
        <Modal show={modal.open} onHide={closeModal}>
            <Modal.Header closeButton>
                <Modal.Title>{modal.loading ? 'Loading user...' : `User #${modal.item?.ID}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {modal.loading === false ? <Form onSubmit={(e) => { e.preventDefault() }}>
                        <h5 className="mb-4"><FaUser /> &nbsp; Persoonlijke Informatie</h5>
                        <Form.Group as={Row} className="mb-3" controlId="f_first_name">
                            <Form.Label column sm={4}>
                                Voornaam
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" name="first_name" value={modal.item?.first_name} onChange={onModalChange} />
                                {'first_name' in modal.errorFields ? <Form.Text className="text-muted">
                                    {modal.errorFields['first_name']}
                                </Form.Text> : ''}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="f_last_name">
                            <Form.Label column sm={4}>
                                Achternaam
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" name="last_name" value={modal.item?.last_name} onChange={onModalChange} />
                                {'last_name' in modal.errorFields ? <Form.Text className="text-muted">
                                    {modal.errorFields['last_name']}
                                </Form.Text> : ''}
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="f_email">
                            <Form.Label column sm={4}>
                                E-mail
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control type="email" name="email" value={modal.item?.email} onChange={onModalChange} />
                                {'email' in modal.errorFields ? <Form.Text className="text-muted">
                                    {modal.errorFields['email']}
                                </Form.Text> : ''}
                            </Col>
                        </Form.Group>
                        <h5 className="mb-4 mt-5"><FaLandmark /> &nbsp; Bankinformatie</h5>
                        <Form.Group as={Row} className="mb-3" controlId="f_iban">
                            <Form.Label column sm={4}>
                                IBAN
                            </Form.Label>
                            <Col sm={8}>
                                <Form.Control type="text" name="iban" value={modal.item?.iban} onChange={onModalChange} />
                                {'iban' in modal.errorFields ? <Form.Text className="text-muted">
                                    {modal.errorFields['iban']}
                                </Form.Text> : ''}
                            </Col>
                        </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="f_swift">
                        <Form.Label column sm={4}>
                            SWIFT
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control type="text" name="swift" value={modal.item?.swift} onChange={onModalChange} />
                            {'swift' in modal.errorFields ? <Form.Text className="text-muted">
                                {modal.errorFields['swift']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    <h5 className="mb-4 mt-5"><FaWallet /> &nbsp; Wallet</h5>
                    <Row className="mb-3">
                        <Col sm={4}>
                            <div>Total</div>
                            <div><strong>&euro;{modal.item?.balance?.pending + modal.item?.balance?.available}</strong></div>
                        </Col>
                        <Col sm={4}>
                            <div>On hold</div>
                            <div><strong>&euro;{modal.item?.balance?.pending}</strong></div>
                        </Col>
                        <Col sm={4}>
                            <div>Available</div>
                            <div><strong>&euro;{modal.item?.balance?.available}</strong></div>
                        </Col>
                    </Row>
                    <h5 className="mb-4 mt-5"><FaUserCog /> &nbsp; Account Status</h5>
                    <Form.Group as={Row} className="mb-3" controlId="f_suspended">
                        <Col sm={12}>
                            <Form.Check name="suspended" label="Suspended" value="1" checked={!!modal.item?.suspended} onChange={onModalCheckChange} />
                            {'suspended' in modal.errorFields ? <Form.Text className="text-muted">
                                {modal.errorFields['suspended']}
                            </Form.Text> : ''}
                        </Col>
                    </Form.Group>
                    </Form> : <div className="text-center py-4"><Spinner animation="border" variant="primary" /></div> }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="link" onClick={closeModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleModalSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </div>
}